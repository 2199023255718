import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import { COLORS } from './../../constants'

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '& .expand-icon': {
      color: COLORS.black,
      display: 'block',
    },
    '& .close-icon': {
      color: COLORS.black,
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      '& .expand-icon': {
        display: 'none',
      },
      '& .close-icon': {
        display: 'block',
      },
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails)

import * as React from 'react'
import { styles } from './styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'

interface ButtonProps {
  onClick: Function
  icon?: any
  label: string
  onSubmit?: Function
  isSubmitting?: boolean
  disabled?: boolean
  customStyle?: any
  customTextStyle?: any
  hoverEffects?: boolean
}

const CHRButton = ({
  onClick,
  icon,
  label,
  isSubmitting,
  disabled,
  customStyle,
  customTextStyle,
  hoverEffects,
  ...props
}: ButtonProps) => {
  const classes = styles()
  const buttonClass = classNames({
    [classes.container]: true,
    [customStyle]: customStyle,
    [classes.hoverEffect]: hoverEffects,
    [classes.disabled]: disabled,
  })
  return (
    <button
      type="button"
      className={buttonClass}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {icon && <img src={icon} className={classes.icon} alt="buttonIcon" />}
      {isSubmitting ? (
        <CircularProgress color="secondary" size={20} />
      ) : (
        <Typography variant="button" component="p" className={customTextStyle}>
          {label}
        </Typography>
      )}
    </button>
  )
}

export default CHRButton

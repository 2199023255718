import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  footerNavList: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingLeft: 0,
    listStyleType: 'none',
    height: 140,
    width: '100%',
  },
  listItem: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.75rem',
    lineHeight: '14px',
    paddingTop: 15,
    textTransform: 'uppercase',
    letterSpacing: 1.2,
    ['& > a']: {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  footerNavLinksMobile: {
    display: 'flex',
  },

  [`@media (min-width: ${BREAKPOINTS.xsm}px)`]: {
    footerNavList: {
      width: '100%',
      height: 'auto',
    },
    footerDivLinks: {
      display: 'none',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    footerNavList: {
      order: 1,
      width: '35%',
      height: '100px',
    },
    footerNavListMobile: {
      display: 'none',
    },
    footerDivLinks: {
      display: 'block',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
    footerNavList: {
      order: 1,
      height: 100,
    },
  },
}))

import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from './../../constants'

export const styles = makeStyles(theme => ({
  text: {
    fontSize: '1.875rem',
    fontWeight: 300,
    color: theme.palette.common.black,
    letterSpacing: 0,
    lineHeight: 1,
    marginBottom: 40,
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    textTransform: 'lowercase',
  },
  bigText: {
    fontSize: '3.75rem',
    lineHeight: 1.4,
    marginBottom: 20,
  },
  smallText: {
    lineHeight: 1.4,
    marginBottom: 10,
  },
  leftSided: {
    textAlign: 'left',
  },
}))

import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../constants'

export const styles = makeStyles(theme => ({
  welcomeMessageContainer: {
    maxWidth: '750px !important',
    marginBottom: '60px !important',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      marginBottom: '100px !important',
    },
  },
  welcomeMessageTypography: {
    fontSize: '2.28rem !important',
    textAlign: 'left',
  },
  invitationContainer: {
    marginBottom: 20,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      marginBottom: 40,
    },
  },
  invitationTypography: {
    fontFamily: FONTS.Schnyder.SchnyderMLight,
  },
  invitationTypographyDesktop: {
    fontSize: '1.875rem',
  },
  findYourVoiceContainer: {
    whiteSpace: 'nowrap',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      whiteSpace: 'wrap',
    },
  },
  findYourVoiceMobileContainer: {
    fontSize: '2.28rem !important',
  },
  cashBackContainer: {
    fontFamily: FONTS.Schnyder.SchnyderMLight,
  },
  cashBackDesktopContainer: {
    whiteSpace: 'nowrap',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      whiteSpace: 'wrap',
    },
  },
  cashBackMobileContainer: {
    fontSize: '1.28rem !important',
    textAlign: 'left',
  },
  joinNowContainer: {
    fontFamily: FONTS.Schnyder.SchnyderMLight,
  },
  joinNowMobileContainer: {
    fontSize: '1.28rem !important',
    textAlign: 'left',
  },
  whyJoinMobileContainer: {
    fontSize: '2.25rem !important',
  },
  shopProductsContainer: {
    paddingTop: 20,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    margin: '0 auto',
    paddingBottom: 0,
    position: 'relative',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginTop: 0,
    },
  },
  termsWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: 0,
      marginLeft: 15,
      fontSize: '1rem',
      color: theme.palette.common.black,
    },
  },
  bold: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  passwordGuidelines: {
    fontSize: '0.875rem',
    lineHeight: 1.7,
    textAlign: 'center',
    color: COLORS.lightGrey,
    margin: '20px 0 15px 0',
    fontFamily: FONTS.Graphik.GraphikLight,
    letterSpacing: 0.38,
  },
  subTitle: {
    fontSize: '1.875rem',
    lineHeight: 1.1,
    fontWeight: 300,
    color: theme.palette.common.black,
    letterSpacing: 0,
  },
  signInWithEmail: {
    paddingTop: 20,
    position: 'relative',
    width: '100%',
    [`& input::placeholder`]: {
      color: COLORS.black + ' !important',
    },
  },
  errorMessage: {
    color: COLORS.dangerRed,
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    fontFamily: FONTS.Graphik.GraphikRegular,
  },
  error: {
    margin: 0,
    color: COLORS.dangerRed,
  },
  forgotPasswordErrorMsg: {
    color: COLORS.dangerRed,
    margin: 0,
    position: 'absolute',
  },
  switches: {
    margin: '20px 0 20px 0',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: '60px',
    },
  },
  buttonStyles: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '0.75rem',
  },
  switchLabel: {
    fontFamily: FONTS.Graphik.GraphikRegular,
  },
  forgotPassword: {
    color: COLORS.black,
    fontSize: '0.875rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    margin: '20px 0',
    cursor: 'pointer',
  },
  desktop: {
    display: 'block',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
      lineHeight: 1.2,
      fontSize: '2.57rem',
    },
  },
  signInText: {
    fontSize: '2.5rem',
  },
  [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
    fontSize: '2.57rem',
  },
  resetError: {
    color: COLORS.dangerRed,
    margin: 0,
    fontFamily: FONTS.Graphik.GraphikRegular,
    minHeight: 50,
  },
  input: {
    marginBottom: 20,
    marginTop: 5,
  },
  successMsg: {
    color: COLORS.successGreen,
    margin: 0,
    position: 'absolute',
  },
  wrapper: {
    position: 'relative',
  },
  passwordField: {
    display: 'flex',
    position: 'relative',
    marginTop: '10px',
  },
  passVisibility: {
    position: 'absolute',
    right: '3%',
    top: '35%',
    color: '#5e676f',
    cursor: 'pointer',
    fontSize: 13,
  },
  hiddenInput: {
    display: 'none',
  },
  centered: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 50,
    marginTop: 100,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      textAlign: 'center',
      marginBottom: 20,
      marginTop: 30,
    },
  },
  underLinedText: {
    textDecoration: 'underline',
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.common.black,
  },
  customWidth1: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 390,
    },
  },
  customWidth2: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 360,
    },
  },
  customWidth3: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 420,
    },
  },
  customWidth4: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 440,
    },
  },
  customWidth5: {
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: 284,
    },
  },
  customWidth6: {
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: 270,
    },
  },
  customWidth7: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: 450,
    },
  },
  floatRightButton: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      float: 'right',
    },
  },
  coloredHeader: {
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    backgroundColor: '#175972',
    paddingBottom: 1,
    marginBottom: 100,
  },
  whiteText: {
    color: COLORS.white,
  },
  coloredButton: {
    backgroundColor: '#fff !important',
    border: 'none !important',
    '& p': {
      color: '#000 !important',
    },
  },
  edgeSpace: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  bottomStrip: {
    color: COLORS.black,
    marginTop: 10,
    fontSize: '0.875rem',
    fontFamily: FONTS.Graphik.GraphikLight,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginTop: 15,
    },
  },
}))

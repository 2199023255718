import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from './../../constants'

export const styles = makeStyles(theme => ({
  newsletterForm: {
    position: 'relative',
    height: 54,
    ['& > input']: {
      backgroundColor: theme.palette.secondary.main,
      fontSize: '0.75rem',
    },
    [`@media (min-width: ${BREAKPOINTS.xl + 1}px)`]: {
      width: '85%',
    },
    [`@media (max-width: ${BREAKPOINTS.xl}px)`]: {
      width: '95%',
    },
    [`@media (min-width: ${BREAKPOINTS.xsm}px)`]: {
      width: '100%',
    },
  },
  newsletterButton: {
    position: 'absolute',
    height: '96%',
    top: '5%',
    right: 0,
    maxWidth: '25%',
    minWidth: '75px',
    background: 'white',
    border: 'none',
    outline: 'none',
  },
  newsletterArrow: {
    maxWidth: '100%',
    width: 50,
    cursor: 'pointer',
  },

  [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
    newsletterChild: {
      width: '45%',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    newsletterChild: {
      order: 3,
      width: '40%',
    },
  },
  validationMsg: {
    position: 'absolute',
    left: 0,
    top: -20,
    fontSize: 14,
    margin: 0,
  },
  errorMsg: {
    color: COLORS.dangerRed,
  },
  successMsg: {
    color: COLORS.successGreen,
  },
}))

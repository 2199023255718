import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, FONTS, WIDTH_DIMENSIONS } from './../../constants'

export const styles = makeStyles(theme => ({
  container: {
    width: '90%',
    margin: '0 auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    fontFamily: FONTS.Graphik.GraphikRegular,
  },

  [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
    container: {
      width: '90%',
    },
  },
}))

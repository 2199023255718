import { useMediaQuery, useTheme } from '@material-ui/core'
import { Link } from 'gatsby'
import { TFunction } from 'i18next'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { ROUTES } from '../../constants'
import CHRAccordion from '../accordion'
import { styles } from './styles'

interface FooterLinksProps {
  t: TFunction
}

const CHRFooterLinks = ({ t }: FooterLinksProps) => {
  const classes = styles()
  const theme = useTheme()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1024))

  return (
    <ul className={classes.footerNavList}>
      <div className={classes.footerDivLinks}>
        <li className={classes.listItem}>
          <Link to={ROUTES.brands}>{t('shared.brandsAndRetailers')}</Link>
        </li>
        <li className={classes.listItem}>
          <Link to={ROUTES.about}>{t('footer.about')}</Link>
        </li>
        <li className={classes.listItem}>
          <a href={ROUTES.blog} target="_blank" rel="noreferrer">
            {t('footer.blog')}
          </a>
        </li>
        {isSmallScreen && (
          <>
            <li className={classes.listItem}>
              <Link to={ROUTES.faq}>{t('footer.faq')}</Link>
            </li>
            <li className={classes.listItem}>
              <Link to={ROUTES.contactUs}>{t('footer.contactUs')}</Link>
            </li>
          </>
        )}
      </div>
      {!isSmallScreen && (
        <div className={classes.footerDivLinks}>
          <li className={classes.listItem}>
            <Link to={ROUTES.contactUs}>{t('footer.contactUs')}</Link>
          </li>
          <li className={classes.listItem}>
            <Link to={ROUTES.faq}>{t('footer.faq')}</Link>
          </li>
          <li className={classes.listItem}>
            <Link to={ROUTES.help}>{t('footer.help')}</Link>
          </li>
          <li className={classes.listItem}>
            <Link to={ROUTES.howItWorks}>{t('footer.howItWorks')}</Link>
          </li>
        </div>
      )}
      <div className={classes.footerDivLinks}>
        <li className={classes.listItem}>
          <Link to={ROUTES.chirpyestPro}>{t('footer.chirpyestPro')}</Link>
        </li>
        <li className={classes.listItem}>
          <Link to={ROUTES.terms}>{t('footer.terms')}</Link>
        </li>
        <li className={classes.listItem}>
          <Link to={ROUTES.privacy}>{t('footer.privacy')}</Link>
        </li>
        {isSmallScreen && (
          <>
            <li className={classes.listItem}>
              <Link to={ROUTES.help}>{t('footer.help')}</Link>
            </li>
            <li className={classes.listItem}>
              <Link to={ROUTES.howItWorks}>{t('footer.howItWorks')}</Link>
            </li>
          </>
        )}
      </div>

      {/* Mobile Design */}
      <div className={classes.footerNavListMobile}>
        <CHRAccordion
          id={114}
          // variant="p"
          title={t('footer.quickLinks')}
          customStyle={{ backgroundColor: 'initial' }}
        >
          <div className={classes.footerNavLinksMobile}>
            <div style={{ marginRight: '120px' }}>
              <li className={classes.listItem}>
                <Link to={ROUTES.brands}>{t('shared.brandsAndRetailers')}</Link>
              </li>
              <li className={classes.listItem}>
                <Link to={ROUTES.about}>{t('footer.about')}</Link>
              </li>
              <li className={classes.listItem}>
                <a href={ROUTES.blog} target="_blank" rel="noreferrer">
                  {t('footer.blog')}
                </a>
              </li>
            </div>
            <div>
              <li className={classes.listItem}>
                <Link to={ROUTES.howItWorks}>{t('footer.howItWorks')}</Link>
              </li>
              <li className={classes.listItem}>
                <Link to={ROUTES.chirpyestPro}>{t('footer.chirpyestPro')}</Link>
              </li>
              <li className={classes.listItem}>
                <Link to={ROUTES.contactUs}>{t('footer.contactUs')}</Link>
              </li>
            </div>
          </div>
        </CHRAccordion>
        <CHRAccordion
          id={115}
          // variant="p"
          title={t('footer.info')}
          customStyle={{ backgroundColor: 'initial', paddingBottom: 10 }}
        >
          <div className={classes.footerNavLinksMobile}>
            <div style={{ marginRight: '120px' }}>
              <li className={classes.listItem}>
                <Link to={ROUTES.help}>{t('footer.help')}</Link>
              </li>
              <li className={classes.listItem}>
                <Link to={ROUTES.faq}>{t('footer.faq')}</Link>
              </li>
            </div>
            <div>
              <li className={classes.listItem}>
                <Link to={ROUTES.privacy}>{t('footer.privacy')}</Link>
              </li>
              <li className={classes.listItem}>
                <Link to={ROUTES.terms}>{t('footer.terms')}</Link>
              </li>
            </div>
          </div>
        </CHRAccordion>
      </div>
    </ul>
  )
}

export default withTranslation()(CHRFooterLinks)

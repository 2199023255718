import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from './../../constants'

export const styles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.secondary.dark,
    padding: 20,
    marginTop: 5,
    marginBottom: 5,
    border: `2px solid ${COLORS.whiteStain}`,
  },
  icon: {
    height: 24,
    width: 24.48,
    position: 'absolute',
    left: 22.55,
  },
  container: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    letterSpacing: 1.2,
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: 54,
    cursor: 'pointer',
    border: `2px solid ${theme.palette.common.black}`,
    margin: '5px auto',
    width: '100%',
    transition: '0.5s',
    '& p': {
      color: theme.palette.common.white,
    },
  },
  hoverEffect: {
    ['&:hover']: {
      backgroundColor: theme.palette.common.white,
      '& p': {
        color: theme.palette.common.black,
      },
    },
  },
  disabled: {
    background: COLORS.ashGrey,
    border: 'none',
    pointerEvents: 'none',
  },
}))

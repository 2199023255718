import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from './../../constants'

export const styles = makeStyles(theme => ({
  footer: {
    backgroundColor: COLORS.whiteStain,
    marginTop: 'auto',
  },
  footerInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '30px',
  },
  newsletterChild: {
    width: '100%',
    maxWidth: 385,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  newsletterForm: {
    position: 'relative',
    ['& > input']: {
      backgroundColor: 'white',
    },
  },
  newsletterButton: {
    position: 'absolute',
    height: 60,
    top: 5,
    right: 0,
    maxWidth: '25%',
    minWidth: '75px',
    background: 'transparent',
    border: 'none',
  },
  newsletterArrow: {
    maxWidth: '100%',
    width: 40,
    cursor: 'pointer',
  },
  followOnSM: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  followOnSMTitle: {
    fontFamily: FONTS.Graphik.GraphikLight,
    fontSize: '0.875rem',
    lineHeight: '24px',
    letterSpacing: '0.38px',
    minWidth: 70,
  },
  chirpyestLogo: {
    display: 'block',
    margin: 'auto',
    height: 53,
  },
  rightsReserved: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20px',
    fontSize: '14px',
    color: '#ababab',
    fontFamily: FONTS.Graphik.GraphikLight,
    marginTop: 20,
  },

  [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
    footerInnerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    newsletterChild: {
      width: '100%',
      maxWidth: '100%',
    },
    logoChild: {
      width: '100%',
    },
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    footerInnerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logoChild: {
      flex: 1,
      order: 2,
    },
    newsletterChild: {
      order: 3,
      flex: 1,
    },
    followOnSM: {
      justifyContent: 'flex-end',
    },
    chirpyestLogo: {
      height: 85,
    },
  },
}))

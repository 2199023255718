import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { styles } from './styles'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_ALIGNMENT } from './../../constants'
import classNames from 'classnames'

interface TextProps {
  size: string
  align: string
}

const CHRText = ({ children, size, align, ...props }: TextProps) => {
  const classes = styles()
  const textClass = classNames({
    [classes.text]: true,
    [classes.bigText]: size === TYPOGRAPHY_SIZES.big,
    [classes.smallText]: size === TYPOGRAPHY_SIZES.small,
    [classes.leftSided]: align === TYPOGRAPHY_ALIGNMENT.left,
  })
  return (
    <Typography align="center" className={textClass} {...props}>
      {children}
    </Typography>
  )
}

export default CHRText

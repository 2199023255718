import { string, object } from 'yup';

let schema = object().shape({
  email: string().required().email(),
  password: string().required().min(8)
    .matches(/[A-Z]/, 'password must have at least one uppercase character')
    .matches(/[a-z]/, 'password must have at least one lowercase character')
    .matches(/[0-9]/, 'password must have at least 1 number'),
});

export const validation = (values) => schema.validate(values, { abortEarly: false })

let signInSchema = object().shape({
  email: string().required().email(),
  password: string().required()
});

export const signInValidation = (values) => signInSchema.validate(values, { abortEarly: false })

export const checkIfSubmittable = (event, submitFunction: Function, ...rest) => {
  if (event && event.charCode === 13) {
    submitFunction(...rest)
  }
}

let emailSchema = object().shape({
  email: string().required('emailRequired').email('invalidEmail'),
});


export const emailValidation = (values) => emailSchema.validate(values, { abortEarly: false })

const accountSettingSchema = object().shape({
  email: string().required().email(),
  fullName: string().required('fullNameRequired'),
  userName: string().required('userNameRequired'),
});

export const accountSettingValidation = (values) => accountSettingSchema.validate(values, { abortEarly: false })

const passwordSettingSchema = object().shape({
  password: string().required('passwordRequired').min(8, 'shortPassword')
    .matches(/[A-Z]/, 'uppercaseChar')
    .matches(/[a-z]/, 'lowercaseChar')
    .matches(/[0-9]/, 'number'),
  confirmPassword: string().required('confirmPasswordRequired')
});

export const passwordValidation = (values) => passwordSettingSchema.validate(values, { abortEarly: false })

let error;

export const getErrorMsg = (errors, fieldName) => {
  if ((errors.inner) && (error = errors.inner.find((err) => err.path === fieldName)) !== undefined) {
    return error.errors[0];
  } else {
    return '';
  }
};

const profileInfoSchema = object().shape({
  firstName: string()
    .required("first name is required."),
  lastName: string()
    .required("last name is required."),
  userName: string()
    .matches(/^[a-zA-Z0-9-_]*$/, 'username can contain letters or numbers only')
    .required("username is required.")
})

export const profileInfoValidation = values => profileInfoSchema.validate(values, { abortEarly: false });

const contactUsSchema = object().shape({
  fullName: string().required("fullNameRequired"),
  email: string().required('emailRequired').email('invalidEmail'),
  type: string().required("typeRequired"),
  message: string().required("messageRequired"),
})

export const contactUsValidation = values => contactUsSchema.validate(values, { abortEarly: false });

export const personalInfoComplete = (userProfile: any): boolean => {
  // when user not signed in userProfile will be an empty object
  // so check of userName prop exists, which mean user is signed in
  if (userProfile && !userProfile.hasOwnProperty('userName'))
    return true;
  if (!userProfile || !userProfile.userName)
    return false;

  const splits = userProfile.fullName.split(' ');
  return splits.length > 1;
}


let paymentSchema = object().shape({
  email: string().email('invalidEmail'),
  venmoAccount: string().test('len', 'invalid venmo account phone number.', val => {
    if (!val) return true
    const val_length_without_dashes = val.replace(/-|_/g, '').trim().length
    return val_length_without_dashes === 10
  }),
})
export const paymentValidation = values =>
  paymentSchema.validate(values, { abortEarly: false })

import React from 'react'
// import Typography from '@material-ui/core/Typography'
import { Accordion, AccordionSummary, AccordionDetails } from './styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

interface CHRAccordionProps {
  id?: any
  title: string
  variant?: string
  customStyle?: any
  children: React.ReactNode
}

const CHRAccordion = ({
  id,
  title,
  children,
  variant,
  customStyle,
}: CHRAccordionProps) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1')
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false)
  }

  const expandStatusIcon = (
    <>
      <span className="expand-icon">
        <AddIcon />
      </span>
      <span className="close-icon">
        <RemoveIcon />
      </span>
    </>
  )
  return (
    <div>
      <Accordion
        square
        expanded={expanded === title}
        onChange={handleChange(title)}
        style={customStyle}
      >
        <AccordionSummary
          aria-controls={'panel1d-content' + id}
          id={'panel1d-header' + id}
          expandIcon={expandStatusIcon}
        >
          {/* <Typography variant={variant || 'h2'} component={variant || 'h2'}>
            {title}
          </Typography> */}
          <p>{title}</p>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  )
}

export default CHRAccordion

import Auth from "@aws-amplify/auth";

export const isUserAuthenticated = async () => {
  try {
    const result = await Auth.currentSession();
    return result
  } catch (error) {
    return false;
  }
};

import React, { useState } from 'react'
import { styles } from './styles'
import CHRInput from '../input'
import arrowRightIcon from './../../assets/images/arrow-right.svg'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ENDPOINTS } from '../../constants'
import axios from '../../axios'
import { emailValidation, getErrorMsg } from '../../../utils/validation'

interface NewsLetterProps {
  t: TFunction
}
const CHRNewsLetter = ({ t }: NewsLetterProps) => {
  const classes = styles()

  const [email, setEmail] = useState('')
  const [emailErrorMsg, setEmailErrorMsg] = useState('')
  const [emailMsg, setEmailMsg] = useState('')

  const handleSubscribeButtonClick = async event => {
    event.preventDefault()

    try {
      const isFormValid = await emailValidation({ email })
      if (isFormValid) {
        await axios.post(ENDPOINTS.newsLetter, { email })

        setEmail('')
        setEmailMsg('userSubscribed')
        await new Promise(done => setTimeout(() => done(), 3000))
        setEmailMsg('')
      }
    } catch (error) {
      if (error?.response?.data?.message === '"email" must be a valid email') {
        setEmailMsg('')
        setEmailErrorMsg('invalidEmail')
      } else {
        setEmailMsg('')
        setEmailErrorMsg(getErrorMsg(error, 'email'))
      }
    }
  }

  return (
    <>
      <form className={classes.newsletterForm}>
        <p className={`${classes.validationMsg} ${classes.errorMsg}`}>
          {emailErrorMsg && t(`dashboard.${emailErrorMsg}`)}
        </p>
        <p className={`${classes.validationMsg} ${classes.successMsg}`}>
          {emailMsg && t(`dashboard.${emailMsg}`)}
        </p>
        <CHRInput
          type="email"
          value={email}
          placeholder={t('shared.subscribeToNewsLetter')}
          onChange={event => {
            setEmail(event.target.value)
            setEmailErrorMsg('')
          }}
        />
        <button
          className={classes.newsletterButton}
          onClick={handleSubscribeButtonClick}
        >
          <img
            src={arrowRightIcon}
            alt={t('imageAlts.submitButton')}
            className={classes.newsletterArrow}
          />
        </button>
      </form>
    </>
  )
}
export default withTranslation()(CHRNewsLetter)

// IMPROVEMENT: refactor all configs into a configs folder and use yup to validate them
// for reference https://bitbucket.org/kiitos/chirpyest_backend/src/development/src/config/

if (
  !process.env.GATSBY_COGNITO_REGION ||
  !process.env.GATSBY_COGNITO_USER_POOL_ID ||
  !process.env.GATSBY_COGNITO_CLIENT_ID
) {
  throw new Error('Environment variables for aws cognito do not exist!')
}

const awsConfig = {
  region: process.env.GATSBY_COGNITO_REGION,
  userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
  //authenticationFlowType: 'USER_PASSWORD_AUTH',
  Auth: {
    identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
    region: 'us-east-2',
    oauth: {
      domain: [process.env.GATSBY_OAUTH_DOMAIN],
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: process.env.GATSBY_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.GATSBY_OAUTH_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.GATSBY_S3_BUCKET_NAME,
      region: 'us-east-2',
    },
  },
}

export default awsConfig

import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from './../../constants'

export const styles = makeStyles(theme => ({
  socialMediaList: {
    display: 'flex',
    listStyleType: 'none',
    paddingLeft: 0,
  },
  socialListItem: {
    marginLeft: 30,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      marginLeft: 15,
    },
  },
  socialIcon: {
    display: 'block',
    width: 25,
    height: 25,
    ['& > img']: {
      maxWidth: '100%',
    },
  },
}))

import * as React from 'react'
import { styles } from './styles'

interface ContentContainerProps {
  children: React.ReactNode
  customStyle: any
}

const CHRContentContainer = (props: ContentContainerProps) => {
  const { children, customStyle = '' } = props
  const classes = styles()

  return <div className={`${classes.container} ${customStyle}`}>{children}</div>
}

export default CHRContentContainer

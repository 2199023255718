import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from './../../constants'

export const styles = makeStyles(theme => ({
  input: {
    display: 'block',
    backgroundColor: COLORS.whiteStain,
    padding: 20,
    border: `2px solid ${COLORS.whiteStain}`,
    width: '100%',
    height: '54px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    letterSpacing: 1.2,
    fontSize: '0.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 'none',
    '&::placeholder': {
      color: COLORS.lightGrey,
      fontFamily: FONTS.Graphik.GraphikRegular,
    },
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '0.75rem',
    },
  },
  error: {
    borderColor: COLORS.dangerRed,
    borderWidth: 1,
  },
  disabled: {
    background: COLORS.whiteStain,
    border: 'none',
    cursor: 'not-allowed',
    pointerEvents: 'none',
    color: COLORS.ashGrey,
  },
}))
